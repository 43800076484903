import { combineReducers } from '@reduxjs/toolkit';

import backofficeApi from 'dux/api/backoffice';
import authReducer from 'dux/auth/slice';
import BoxesReducer from 'dux/boxes/slice';
import bufferSpacesReducer from 'dux/bufferSpaces/slice';
import couponsReducer from 'dux/coupons/slice';
import customersReducer from 'dux/customer/slice';
import exportsReducer from 'dux/exports/slice';
import FastConsultationReducer from 'dux/fastconsultation/slice';
import featureFlagsReducer from 'dux/featureFlags/slice';
import labInfosReducer from 'dux/lab-infos/slice';
import metadataReducer from 'dux/metadata/slice';
import ordersReducer from 'dux/orders/slice';
import paginationReducer from 'dux/pagination/slice';
import partialRefundReducer from 'dux/partialRefund/slice';
import productionReducer from 'dux/production/reducers';
import settingsReducer from 'dux/settings/slice';
import subscriptionsReducer from 'dux/subscriptions/slice';
import surveysReducer from 'dux/surveys/slice';
import teammatesReducer from 'dux/teammates/slice';
import variantsReducer from 'dux/variants/slice';

const createRootReducer = routerReducer =>
  combineReducers({
    [backofficeApi.reducerPath]: backofficeApi.reducer,
    router: routerReducer,
    auth: authReducer,
    boxes: BoxesReducer,
    bufferSpaces: bufferSpacesReducer,
    coupons: couponsReducer,
    customers: customersReducer,
    exports: exportsReducer,
    fastconsultation: FastConsultationReducer,
    featureFlags: featureFlagsReducer,
    metadata: metadataReducer,
    orders: ordersReducer,
    pagination: paginationReducer,
    partialRefund: partialRefundReducer,
    production: productionReducer,
    settings: settingsReducer,
    labInfos: labInfosReducer,
    subscriptions: subscriptionsReducer,
    surveys: surveysReducer,
    teammates: teammatesReducer,
    variants: variantsReducer,
  });

export default createRootReducer;
